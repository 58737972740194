<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>{{$t('tools/swift.title-validation')}}</h2>
    </div>
    <div class="ibox-content p-md">

      <form role="form" @submit="validateSWIFT" name="validateSWIFTForm">
        <div class="form-group row">
          <label for="validateSWIFT" class="col-lg-3 col-form-label">{{ $t('tools/swift.form.swift') }}</label>
          <div class="col-lg-9">
            <input id="validateSWIFT" type="text" :placeholder="$t('tools/swift.form.swift_placeholder')" class="form-control" v-model="form.swift" required>
          </div>
        </div>

        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('tools/swift.form.button') }}</button>
      </form>

      <div class="row results" v-if="listResults.length > 0">
        <div class="col-md-auto" v-for="(result, index) in listResults" :key="index">
          <div :class="'panel ' + (result.validated ? 'panel-primary' : 'panel-danger')">
            <div class="panel-heading">
                {{ result.validated ? $t('tools/swift.results.valid') : $t('tools/swift.results.invalid') }}
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-auto">
                  <div class="row m-sm">{{ $t('tools/swift.results.swift') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.bank">{{ $t('tools/swift.results.bank') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.city">{{ $t('tools/swift.results.city') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.branch">{{ $t('tools/swift.results.branch') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.address">{{ $t('tools/swift.results.address') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.postcode">{{ $t('tools/swift.results.postcode') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.countryName">{{ $t('tools/swift.results.countryName') }}</div>
                </div>
                <div class="col">
                  <div class="row m-sm">{{ result.SWIFT }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.bank">{{ result.data.bank }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.city">{{ result.data.city }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.branch">{{ result.data.branch }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.address">{{ result.data.address }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.postcode">{{ result.data.postcode }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.countryName">{{ result.data.countryName }}</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
  .row.results {
    margin-top: 20px;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import Messages from '../../panels/Messages.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: {}
})
export default class SWIFTValidation extends Vue {

  form:any = {
    swift: null
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  listResults:any[] = [];

  created() {

  }

  mounted() {
    var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=validateSWIFTForm] button.ladda-button' );
    this.laddaSubmit = Ladda.create(submitButton!);
  }

  validateSWIFT(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "SWIFT" : this.form.swift
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/utils/bank/validateSWIFT', input, options).then((response:any) => {
      this.listResults.unshift(response);
      this.laddaSubmit!.stop();
    });
  }

}
</script>