<template>
  <section id="content">
    <PageTitle :title="$t('tools/bank.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row">
          <div class="col-lg-12">
            <IBANValidation></IBANValidation>
          </div>
      </div>

      <div class="row">
          <div class="col-lg-12">
            <SWIFTValidation></SWIFTValidation>
          </div>
      </div>

    </div>

  </section>
</template>

<style scoped>
  
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import PageTitle from '../../panels/PageTitle.vue';
import Messages from '../../panels/Messages.vue';
import IBANValidation from '../../panels/tools/IBANValidation.vue';
import SWIFTValidation from '../../panels/tools/SWIFTValidation.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: {
    PageTitle,
    IBANValidation,
    SWIFTValidation
  }
})
export default class Bank extends mixins(GenericPage) {

}
</script>