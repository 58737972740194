<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>{{$t('tools/iban.title-validation')}}</h2>
    </div>
    <div class="ibox-content p-md">

      <form role="form" @submit="validateIBAN" name="validateIBANForm">
        <div class="form-group row">
          <label for="validateIBAN" class="col-lg-3 col-form-label">{{ $t('tools/iban.form.iban') }}</label>
          <div class="col-lg-9">
            <input id="validateIBAN" type="text" :placeholder="$t('tools/iban.form.iban_placeholder')" class="form-control" v-model="form.iban" required>
          </div>
        </div>

        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('tools/iban.form.button') }}</button>
      </form>

      <div class="row results" v-if="listResults.length > 0">
        <div class="col-md-auto" v-for="(result, index) in listResults" :key="index">
          <div :class="'panel ' + (result.validated ? 'panel-primary' : 'panel-danger')">
            <div class="panel-heading">
                {{ result.validated ? $t('tools/iban.results.valid') : $t('tools/iban.results.invalid') }}
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-auto">
                  <div class="row m-sm">{{ $t('tools/iban.results.iban') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.countryName">{{ $t('tools/iban.results.countryName') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.sepa">{{ $t('tools/iban.results.sepa') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.iban_paper">{{ $t('tools/iban.results.iban_paper') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.checksum">{{ $t('tools/iban.results.checksum') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.bban">{{ $t('tools/iban.results.bban') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.blz">{{ $t('tools/iban.results.blz') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.check_digit">{{ $t('tools/iban.results.check_digit') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.account_number">{{ $t('tools/iban.results.account_number') }}</div>
                  
                  <div class="row m-sm" v-if="result.validated && result.data.bank">{{ $t('tools/iban.results.bank') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.bank_code">{{ $t('tools/iban.results.bank_code') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.city">{{ $t('tools/iban.results.city') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.zip">{{ $t('tools/iban.results.zip') }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.swift_code">{{ $t('tools/iban.results.swift_code') }}</div>
                  
                  
                </div>
                <div class="col">
                  <div class="row m-sm">{{ result.IBAN }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.countryName">{{ result.data.countryName }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.sepa">{{ result.data.sepa }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.iban_paper">{{ result.data.iban_paper }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.checksum">{{ result.data.checksum }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.bban">{{ result.data.bban }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.blz">{{ result.data.blz }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.check_digit">{{ result.data.check_digit }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.account_number">{{ result.data.account_number }}</div>

                  <div class="row m-sm" v-if="result.validated && result.data.bank">{{ result.data.bank }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.bank_code">{{ result.data.bank_code }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.city">{{ result.data.city }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.zip">{{ result.data.zip }}</div>
                  <div class="row m-sm" v-if="result.validated && result.data.swift_code">{{ result.data.swift_code }}</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
  .row.results {
    margin-top: 20px;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import Messages from '../../panels/Messages.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: {}
})
export default class IBANValidation extends Vue {

  form:any = {
    iban: null
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  listResults:any[] = [];

  created() {

  }

  mounted() {
    var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=validateIBANForm] button.ladda-button' );
    this.laddaSubmit = Ladda.create(submitButton!);
  }

  validateIBAN(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "IBAN" : this.form.iban
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/utils/bank/validateIBAN', input, options).then((response:any) => {
      this.listResults.unshift(response);
      this.laddaSubmit!.stop();
    });
  }

}
</script>